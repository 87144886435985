.highlight-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  height: 40%;
}
.highlight-container-badges {
  display: block;
  height: 30px;
  position: relative;
  top: -15px;
  font-size: 15px;
  background: rgb(220, 120, 0);
  min-width: 50%;
  width: fit-content;
  padding: 4px 10px;
  left: 10px;
}

svg {
  width: 100%;
}

.highlight-container h2 {
  font-size: 30px;
  padding: 0px;
  margin: 10px 30px;
}

.highlight-container-tag-cloud span.tag-cloud-tag {
  padding: 0;
  margin: 0 2px !important;
  border: none;
}

.highlight-container-tag-cloud {
  margin: 0 12px;
  margin-top: -17px;
}

.highlight-container-preps {
  padding: 0px 15px;
}

.highlight-container-date {
  font-size: 12px;
  font-weight: 100;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  margin: 8px;
}